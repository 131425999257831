<template>
  <div>
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      :tips="true"
      @selection-change="tableChoose"
    >
      <!-- 搜索条件 -->
      <div slot="tips" class="TitleBox">
        <div class="conditions">
          <div class="pr-2">搜索种类</div>
          <el-select
            class="choose-machine"
            v-model="params.keyword"
            placeholder="请选择状态"
            clearable
            size="mini"
          >
            <el-option v-for="item in searchList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="conditions" v-if="params.keyword!==''">
          <div class="pr-2">输入搜素</div>
          <el-input
            v-model="params.keyvalue"
            :placeholder="params.keyword==='活动名称'?'请输入活动名称':params.keyword==='活动编号'?'请输入活动编号':'请选择搜索种类'"
            class="ipt-default"
            style="width:210px;"
            size="mini"
          ></el-input>
        </div>
        <div class="conditions">
          <div class="pr-2">活动分类</div>
          <el-cascader
            style="width:69%;"
            expand-trigger="hover"
            :options="typeList"
            @change="activityTypeChange"
            :props="{ checkStrictly: true }"
            :clearable="true"
            size="mini"
          ></el-cascader>
        </div>
        <div class="conditions">
          <div class="pr-2">活动状态</div>
          <el-select
            class="choose-machine"
            placeholder="请选择状态"
            v-model="is_auth"
            clearable
            size="mini"
            @change="activateChange"
          >
            <el-option v-for="item in stateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="conditions">
          <div class="pr-2">活动开始日期</div>
          <el-date-picker
            style="width:260px;margin-right:20px;"
            v-model="times"
            type="daterange"
            align="center"
            :editable="false"
            unlink-panels
            size="small"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            @change="timesChange"
          ></el-date-picker>
        </div>
        <div class="conditions">
          <el-button
            @click="serchActiviteList"
            type="primary"
            class="btn-default"
            size="mini"
          >筛选
          </el-button>
        </div>
      </div>
      <!-- 表格上部按钮 -->
      <div slot="toolbar">
        <el-button class="toolbarbtn" @click="newMould">使用空的新增模板创建活动</el-button>
        <el-button class="toolbarbtn" @click="copyMould">复制已有活动作为模板创建活动</el-button>
      </div>
      <template slot="table-columns">
        <el-table-column label=" " width="55" fixed="left">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked" @change="tableChoose(scope.row.index)"></el-checkbox>
          </template>
        </el-table-column>
        <!--活动名称-->
        <el-table-column label="活动名称" width="225" fixed="left">
          <template slot-scope="scope">
            <div class="imgBox">
              <img :src="scope.row.thumbnail"/>
              <div :title="scope.row.goods_name" class="tag" @click="copyTitle(scope.row.goods_name)">{{ scope.row.goods_name }}</div>
            </div>
          </template>
        </el-table-column>
        <!--活动编号-->
        <el-table-column prop="sn" label="活动编号"/>
        <!--活动一级分类-->
        <el-table-column prop="category_first" label="活动一级分类"/>
        <!--活动二级分类--->
        <el-table-column prop="category_second" label="活动二级分类"/>
        <!--原价-->
        <el-table-column label="原价">
          <template slot-scope="scope">
            <div
              style="color:#fe5558;"
            >{{ scope.row.mkt_low?scope.row.mkt_low:0 }}元{{ scope.row.mkt_low === scope.row.mkt_high ? '' : '起' }}
            </div>
          </template>
        </el-table-column>
        <!--销售价-->
        <el-table-column label="销售价">
          <template slot-scope="scope">
            <div
              style="color:#fe5558;"
            >{{ scope.row.price_low }}元{{ scope.row.price_low === scope.row.price_top ? '' : '起' }}
            </div>
          </template>
        </el-table-column>
        <!--累计销量-->
        <el-table-column prop="buy_count" label="累计销量"/>
        <!--名额限制-->
        <el-table-column prop="sale_limit_num" label="名额限制"/>
        <!--剩余名额-->
        <el-table-column prop="enable_quantity" label="剩余名额"/>
        <!--申请上架时间-->
        <el-table-column prop="apply_time" :formatter="MixinUnixToDate" label="申请上架时间"/>
        <!--审核时间-->
        <el-table-column prop="auth_time" :formatter="MixinUnixToDate" label="审核时间"/>
        <!--更新时间-->
<!--        <el-table-column prop="update_date" :formatter="MixinUnixToDate" label="更新时间"/>-->
        <!--活动开始时间-->
        <el-table-column prop="min_time" :formatter="MixinUnixToDate" label="活动开始时间"/>
        <!--活动结束时间-->
        <el-table-column prop="max_time" :formatter="MixinUnixToDate" label="活动结束时间"/>
        <!--活动链接地址-->
        <el-table-column prop="act_url" label="活动链接地址"/>
        <!--状态-->
        <el-table-column label="状态" fixed="right">
          <template slot-scope="scope">
            <div
              :style="scope.row.is_auth === 0 ? 'color:#3471cd;' : scope.row.is_auth === 2 ? 'color:#fe5558;' : scope.row.is_auth === 3 ? 'color:#008080;' : scope.row.is_auth === 1 && scope.row.market_enable === 0 ? 'color:#222;' : 'color:#ff9d23;'"
            >
              {{
                scope.row.is_auth === 0 ? "待上架" : scope.row.is_auth === 2 ? "审核拒绝" : scope.row.is_auth === 3 ? "审核中" : scope.row.is_auth === 1 && scope.row.market_enable === 0 ? "已下架" : "已上架"
              }}
            </div>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <div class="buttonBox">
              <!-- <el-button
                @click="openDialog('copy',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.is_auth===1"
                style="margin-bottom:5px;"
              >复制链接
              </el-button> -->
              <el-button
                @click="openDialog('down',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.is_auth===1&&scope.row.market_enable!==0"
                style="margin-bottom:5px;color:#fe5558;"
              >下架
              </el-button>
              <el-button
                @click="openDialog('up',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.is_auth===0||scope.row.is_auth===2"
                style="margin-bottom:5px;"
              >申请上架
              </el-button>
              <el-button
                @click="openDialog('del',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.is_auth===0"
                style="margin-bottom:5px;color:#fe5558;"
              >删除
              </el-button>
              <el-button
                @click="openDialog('back',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.is_auth===3"
                style="margin-bottom:5px;color:#fe5558;"
              >撤回
              </el-button>
              <el-button
                @click="edit('edit',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.is_auth !== 3"
                style="margin-bottom:5px;"
              >编辑
              </el-button>
              <el-button
                @click="openDialog('reason',scope.row)"
                type="text"
                size="mini"
                v-if="scope.row.is_auth === 2"
                style="margin-bottom:5px;"
              >查看拒绝原因
              </el-button>
              <el-button
                @click="edit('details',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;"
              >查看详情
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData.data"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="dialogContent">{{ dialogContent }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="centerDialogVisible = false"
          v-if="dialogTitle === '活动上架确认' || dialogTitle === '活动下架确认' || dialogTitle === '删除活动' || dialogTitle === '撤回申请'"
        >取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_activityType from "@/api/activityType";
import * as API_activityList from "@/api/activityList";
import {$$router} from "@/libs/router/router";
import Clipboard from 'clipboard'

export default {
  name: "activityList",
  data() {
    return {
      //  列表loading状态
      loading: false,
      //  列表参数
      is_auth: '',
      params: {
        page_no: 1,
        page_size: 10,
        keyword: "",
        category_first: null,
        category_second: null,
        is_auth: "",
        active_start: "",
        active_end: "",
        market_enable: ""
      },

      //  列表数据
      // 0待审核，1审核通过，2审核拒绝，3:审核中
      tableData: {},

      // 弹框显示内容
      dialogTitle: "",
      dialogContent: "",
      centerDialogVisible: false,

      // 搜索种类
      searchList: ["活动名称", "活动编号"],

      // 活动分类
      typeList: [],

      // 活动状态
      stateList: [
        {
          id: "0",
          name: "待上架",
        },
        {
          id: "1",
          name: "已上架",
        },
        {
          id: '2',
          name: '已下架'
        },
        {
          id: "3",
          name: "审核拒绝",
        },
        {
          id: "4",
          name: '审核中'
        }
      ],

      // 活动开始时间
      times: [],

      // 按钮选中的列表行
      chooseRow: "",

      // 左边选择框选中的列表行
      tableChooseRow: "",
    };
  },
  mounted() {
    // 获取活动分类列表
    this.GET_activeTypeList();

    // 获取活动列表
    if (this.$route.query.type) {
      this.params.is_auth = this.$route.query.type;
    }
    this.POST_activeGoodsActivityPage();
  },
  activated() {
    // 获取活动分类列表
    this.GET_activeTypeList();

    // 获取活动列表
    this.POST_activeGoodsActivityPage();
  },
  methods: {
    /** 复制标题 */
    copyTitle(str) {
      const clipboard = new Clipboard('.tag', {
        text: function () {
          return str
        }
      })
      clipboard.on('success', e => {
        this.$message({message: '复制成功', showClose: true, type: 'success'})
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        this.$message({message: '复制失败,', showClose: true, type: 'error'})
        clipboard.destroy()
      })
      // this.$message.success('复制成功')
    },
    /** 活动状态切换 */
    activateChange(e) {
      if (e === '0') {
        this.params.is_auth = 0
        this.params.market_enable = 0
      } else if (e === '1') {
        this.params.is_auth = 1
        this.params.market_enable = 1
      } else if (e === '2') { // 已下架
        this.params.is_auth = 1
        this.params.market_enable = 0
      } else if (e === '3') {
        this.params.is_auth = 2
        this.params.market_enable = 0
      } else if (e === '4') {
        this.params.is_auth = 3
        this.params.market_enable = 0
      } else {
        this.params.is_auth = ''
        this.params.market_enable = ''
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.POST_activeGoodsActivityPage();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.POST_activeGoodsActivityPage();
    },

    /**  获取活动分类列表 */
    GET_activeTypeList() {
      API_activityType.activeTypeList().then((res) => {
        // console.log(res);
        var typeList = [];
        for (var name in res) {
          typeList.push(res[name]);
        }
        this.typeList = [];
        typeList.forEach((item1, index1) => {
          var jsonParent = {};
          jsonParent.value = item1.category_id;
          jsonParent.label = item1.name;
          var children = [];
          if (item1.children) {
            item1.children.forEach((item2, index2) => {
              var jsonChild = {};
              jsonChild.value = item2.category_id;
              jsonChild.label = item2.name;
              children.push(jsonChild);
            });
          }
          jsonParent.children = children;
          this.typeList.push(jsonParent);
        });
      });
    },

    /**  选择活动分类 */
    activityTypeChange(val) {
      this.params.category_first = null;
      this.params.category_second = null;
      this.typeList.forEach((item1) => {
        if (item1.value === val[0]) {
          this.params.category_first = item1.value;
          if (val[1]) {
            item1.children.forEach((item2) => {
              if (item2.value === val[1]) {
                this.params.category_second = item2.value;
              }
            });
          }
        }
      });
    },

    /**  选择查询时间 */
    timesChange() {
      console.log(this.times);
      if (this.times) {
        if (this.times[0] === this.times[1]) {
          this.params.active_start = this.times[0] / 1000;
          this.params.active_end = this.times[1] / 1000 + 86399;
        } else {
          this.params.active_start = this.times[0] / 1000;
          this.params.active_end = this.times[1] / 1000 + 86399;
        }
      } else {
        this.params.active_start = "";
        this.params.active_end = "";
      }
    },

    /** 查询活动列表 */
    serchActiviteList() {
      this.params.page_no = 1
      this.POST_activeGoodsActivityPage()
    },

    /** 获取活动列表 */
    POST_activeGoodsActivityPage() {
      // this.params.page_no = 1
      API_activityList.activeGoodsActivityPage(this.params).then((res) => {
        console.log(res)
        this.tableData = res;
        this.tableData.data.forEach((item, index) => {
          item.checked = false;
          item.index = index;
        });
        this.tableChooseRow = "";
      });
    },

    /** 删除活动 */
    DELTET_activeGoodsActivityDel() {
      API_activityList.activeGoodsActivityDel(this.chooseRow.goods_id).then(
        (res) => {
          // console.log(res);
          this.$message.success("删除成功！");
          this.chooseRow = "";
          this.POST_activeGoodsActivityPage();
        }
      );
    },

    /** 活动申请上架 */
    GET_activeGoodsActivityUpdate() {
      API_activityList.activeGoodsActivityUpdate({
        goods_id: this.chooseRow.goods_id,
      }).then((res) => {
        // console.log(res);
        this.$message.success("申请上架成功，请等待审核！");
        this.chooseRow = "";
        this.POST_activeGoodsActivityPage();
      });
    },

    /** 活动申请撤回 */
    GET_activeGoodsActivityUpdateRemove() {
      API_activityList.activeGoodsActivityUpdateRemove({
        goods_id: this.chooseRow.goods_id,
      }).then((res) => {
        // console.log(res);
        this.$message.success("撤回成功！");
        this.chooseRow = "";
        this.POST_activeGoodsActivityPage();
      });
    },

    /** 活动下架 */
    PUT_activeGoodsActivityUnder() {
      API_activityList.activeGoodsActivityUnder(this.chooseRow.goods_id).then(
        (res) => {
          // console.log(res);
          this.$message.success("下架成功！");
          this.chooseRow = "";
          this.POST_activeGoodsActivityPage();
        }
      );
    },

    /** 打开弹窗 */
    openDialog(type, row) {
      this.centerDialogVisible = true;
      this.chooseRow = row;
      if (type === "copy") {
        this.dialogTitle = "复制链接成功";
        this.dialogContent = "复制成功，点击确定返回";
      } else if (type === "up") {
        this.dialogTitle = "活动上架确认";
        this.dialogContent = "上架需要审核，确认上架吗？";
      } else if (type === "down") {
        this.dialogTitle = "活动下架确认";
        this.dialogContent = "下架后将不允许报名参与，确认下架吗？";
      } else if (type === "del") {
        this.dialogTitle = "删除活动";
        this.dialogContent = "删除活动将无法找回，确认删除吗？";
      } else if (type === "back") {
        this.dialogTitle = "撤回申请";
        this.dialogContent = "工作人员加紧审核中，确认撤回申请吗？";
      } else if (type === "reason") {
        this.dialogTitle = "审核未通过";
        this.dialogContent = row.auth_message;
      }
    },

    /** 弹窗点击确定 */
    ok() {
      if (this.dialogTitle === "活动上架确认") {
        this.GET_activeGoodsActivityUpdate();
      } else if (this.dialogTitle === "活动下架确认") {
        this.PUT_activeGoodsActivityUnder();
      } else if (this.dialogTitle === "删除活动") {
        this.DELTET_activeGoodsActivityDel();
      } else if (this.dialogTitle === "撤回申请") {
        this.GET_activeGoodsActivityUpdateRemove();
      }
      this.centerDialogVisible = false;
    },

    /** 点击编辑按钮页面跳转 */
    edit(type, row) {
      if (type === 'edit') {
        if (row.is_auth === 1 && row.market_enable !== 0) {
          $$router
            .get()
            .forward("活动详情", {id: row.goods_id, type: "editUp"});
        } else {
          $$router.get().forward("活动详情", {id: row.goods_id, type: "edit"});
        }
      } else {
        this.$router.push({
          name: '查看活动详情',
          params: { id: row.goods_id },
        });
        return false;
      }
    },

    /** 新创建模板 */
    newMould() {
      $$router.get().forward("活动详情");
    },

    /** 复制已有模板 */
    copyMould() {
      if (this.tableChooseRow === "") {
        this.$message.error("至少选择一个活动！");
        return false;
      }
      $$router.get().forward("活动详情", {
        id: this.tableChooseRow.goods_id,
        type: "newCopy",
      });
    },

    /** 选中列表行 */
    tableChoose(index) {
      this.tableData.data.forEach((item) => {
        item.checked = false;
      });
      var chooseRow = this.tableData.data[index];
      chooseRow.checked = true;
      this.tableData.data.splice(index, 1, chooseRow);
      this.tableChooseRow = chooseRow;
      console.log(this.tableChooseRow);
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

.TitleBox {
  display: flex;
  flex-wrap: wrap;

  .conditions {
    display: flex;
    align-items: center;

    div {
      font-size: 15px;
      font-weight: 500;
      color: rgba(68, 68, 68, 1);
    }

    margin-bottom: 20px;
    margin-right: 20px;

    .choose-machine {
      width: 60%;
    }
  }
}

.imgBox {
  display: flex;
  align-items: center;

  img {
    width: 64px;
    height: 48px;
    margin-right: 10px;
  }

  div {
    width: 150px;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogContent {
  font-size: 16px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}

.toolbarbtn{
  color:#fff;
  background:teal;
}
.toolbarbtn:hover{
  color:#fff;
  background:#34CDBD;
}
</style>
