/**
 * 活动列表相关API
 */

import request from '@/utils/request'

/**
 * 查询活动列表
 * @param params
 */
export function activeGoodsActivityPage(params) {
  return request({
    url: '/active/goodsActivity/page',
    method: 'post',
    params
  })
}

/**
 * 删除活动
 * @param id
 */
export function activeGoodsActivityDel(id) {
  return request({
    url: `/active/goodsActivity/${id}`,
    method: 'delete'
  })
}

/**
 * 活动申请上架
 * @param params
 */
export function activeGoodsActivityUpdate(params) {
  return request({
    url: '/active/goodsActivity/update',
    method: 'get',
    params
  })
}

/**
 * 活动申请下架
 * @param id
 */
export function activeGoodsActivityUnder(id) {
  return request({
    url: `/active/goodsActivity/${id}/under`,
    method: 'put'
  })
}

/**
 * 活动申请撤回
 * @param params
 */
export function activeGoodsActivityUpdateRemove(params) {
  return request({
    url: '/active/goodsActivity/update_remove',
    method: 'get',
    params
  })
}
